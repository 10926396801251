<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('KorisnickoIme')"
            label-for="korisnickoime"
          >
            <b-form-input
              v-model="optionsLocal.korisnickoime"
              disabled
              :placeholder="$t('KorisnickoIme')"
              name="korisnickoime"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <b-form-input
              v-model="optionsLocal.email"
              disabled
              name="email"
              :placeholder="$t('Email')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Jmbg')"
            label-for="jmbg"
          >
            <b-form-input
              v-model="optionsLocal.jmbg"
              disabled
              name="jmbg"
              :placeholder="$t('Jmbg')"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Ime')"
            label-for="ime"
          >
            <b-form-input
              v-model="optionsLocal.ime"
              disabled
              name="ime"
              :placeholder="$t('Ime')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Prezime')"
            label-for="prezime"
          >
            <b-form-input
              v-model="optionsLocal.prezime"
              disabled
              name="prezime"
              :placeholder="$t('Prezime')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Adresa')"
            label-for="adresa"
          >
            <b-form-input
              v-model="optionsLocal.adresa"
              disabled
              name="adresa"
              :placeholder="$t('Adresa')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Telefon')"
            label-for="telefon"
          >
            <b-form-input
              v-model="optionsLocal.telefon"
              disabled
              name="telefon"
              :placeholder="$t('Telefon')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Mobilni')"
            label-for="mobilni"
          >
            <b-form-input
              v-model="optionsLocal.mobilni"
              disabled
              name="mobilni"
              :placeholder="$t('Mobilni')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumIstekaVozackeDozvole')"
            label-for="datum_isteka_vozacke_dozvole"
          >
            <b-form-input
              v-model="optionsLocal.datum_isteka_vozacke_dozvole_formatirano"
              disabled
              name="datum_isteka_vozacke_dozvole"
              :placeholder="$t('DatumIstekaVozackeDozvole')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumIstekaLicneKarte')"
            label-for="datum_isteka_licne_karte"
          >
            <b-form-input
              v-model="optionsLocal.datum_isteka_licne_karte_formatirano"
              disabled
              name="datum_isteka_licne_karte"
              :placeholder="$t('DatumIstekaLicneKarte')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumIstekaSertifikataZaPotpis')"
            label-for="datum_isteka_sertifikata_za_potpis"
          >
            <b-form-input
              v-model="optionsLocal.datum_isteka_sertifikata_za_potpis_fomatirano"
              disabled
              name="datum_isteka_sertifikata_za_potpis"
              :placeholder="$t('DatumIstekaSertifikataZaPotpis')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="optionsLocal.instruktor"
      >
        <b-col sm="6">
          <b-form-group
            :label="$t('BrojLicenceInstruktora')"
            label-for="broj_licence_instruktora"
          >
            <b-form-input
              v-model="optionsLocal.broj_licence_instruktora"
              disabled
              name="broj_licence_instruktora"
              :placeholder="$t('BrojLicenceInstruktora')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumLicenceInstruktora')"
            label-for="datum_licence_instruktora"
          >
            <b-form-input
              v-model="optionsLocal.datum_licence_instruktora_formatirano"
              disabled
              name="datum_licence_instruktora"
              :placeholder="$t('DatumLicenceInstruktora')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('BrojLekarskog')"
            label-for="broj_lekarskog_instruktora"
          >
            <b-form-input
              v-model="optionsLocal.broj_lekarskog_instruktora"
              disabled
              name="broj_lekarskog_instruktora"
              :placeholder="$t('BrojLekarskog')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumLekarskog')"
            label-for="datum_lekarskog_instruktora"
          >
            <b-form-input
              v-model="optionsLocal.datum_lekarskog_instruktora_formatirano"
              disabled
              name="datum_lekarskog_instruktora"
              :placeholder="$t('DatumLekarskog')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="optionsLocal.predavac"
      >
        <b-col sm="6">
          <b-form-group
            :label="$t('BrojLicencePredavača')"
            label-for="broj_licence_predavaca"
          >
            <b-form-input
              v-model="optionsLocal.broj_licence_predavaca"
              disabled
              name="broj_licence_predavaca"
              :placeholder="$t('BrojLicencePredavača')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumLicencePredavača')"
            label-for="datum_licence_predavaca"
          >
            <b-form-input
              v-model="optionsLocal.datum_licence_predavaca_formatirano"
              disabled
              name="datum_licence_predavaca"
              :placeholder="$t('DatumLicencePredavača')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="optionsLocal.ispitivac"
      >
        <b-col sm="6">
          <b-form-group
            :label="$t('BrojLicenceIspitivača')"
            label-for="broj_licence_ispitivaca"
          >
            <b-form-input
              v-model="optionsLocal.broj_licence_ispitivaca"
              disabled
              name="broj_licence_ispitivaca"
              :placeholder="$t('BrojLicenceIspitivača')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('DatumLicencePredavača')"
            label-for="datum_licence_ispitivaca"
          >
            <b-form-input
              v-model="optionsLocal.datum_licence_ispitivaca_formatirano"
              disabled
              name="datum_licence_ispitivaca"
              :placeholder="$t('DatumLicenceIspitivača')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
